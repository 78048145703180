import ApiService from '@/services/ApiService';

const API_URL = '/api/Admin/Messages/';

class AdminMessageService {
  CreateAnnouncement(message: {}) {
    return ApiService.post(`${API_URL}CreateAnnouncement`, message);
  }

  getDetail(messageId: string) {
    return ApiService.get(`${API_URL}Detail?messageId=${messageId}`);
  }

  getIndex(currentPage: number, sortColumn: string, sortAsc: boolean, tableFilter: string) {
    return ApiService.get(
      `${API_URL}Index?currentPage=${currentPage}&sortColumn=${sortColumn}&sortAsc=${sortAsc}&tableFilter=${tableFilter}`
    );
  }
}

export default new AdminMessageService();
