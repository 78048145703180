













import Vue, { VueConstructor } from 'vue';
import AdminMessageService from '@/services/admin/AdminMessageService';
import StatusUpdater from '@/services/StatusUpdater';
import MessageDetailHeader from '@/components/messages/MessageDetailHeader.vue';
import { formatDistance } from 'date-fns';
import { de } from 'date-fns/locale';
import FormaterMixin from '@/mixins/FormaterMixin.vue';
import { MetaInfo } from 'vue-meta';

class AdminMessageDetailDto {
  body!: string;
  createdAt!: Date;
  id!: string;
  isUnread!: boolean;
  sender!: string;
  subject!: string;
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  mixins: [FormaterMixin],
  components: { MessageDetailHeader },
  props: {
    messageId: {
      type: String,
    },
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.admin.AdminMessageDetail.meta.title', { message: this.message.subject }).toString(),
    };
  },
  data() {
    return {
      message: new AdminMessageDetailDto(),
      ready: false,
    };
  },
  mounted() {
    this.loadData();
    this.setBreadCrumb();
  },
  methods: {
    formatDistance(date: Date): string {
      return formatDistance(date, new Date(), { addSuffix: true, locale: de });
    },

    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { to: { name: 'AdminMessageIndex' }, translationKey: 'pages.admin.AdminMessageDetail.breadcrumb.messages' },
        { translationKey: 'pages.admin.AdminMessageDetail.breadcrumb.last' },
      ]);
    },

    loadData() {
      AdminMessageService.getDetail(this.messageId).then((res) => {
        this.message = res.value;
        StatusUpdater.loadData();
        this.ready = true;
      });
    },
  },
});
